<template>
    <main class="form-signin w-100 m-auto text-center">
      <img class="mb-4" src="/img/logo-full.png" alt="" width="320"/>
      <form @submit.prevent id="authForm" class="needs-validation" novalidate="">
            <h1 class="h3 mb-3 fw-normal">Please sign in</h1>

            <div class="form-floating">
                <input  :class="{ 'is-invalid': _error }" type="text" class="form-control" id="floatingInput" placeholder="Username" v-model="_username" required="required">
                <label for="floatingInput">Username</label>
            </div>
            <div class="form-floating">
                <input  :class="{ 'is-invalid': _error }" type="password" class="form-control" id="floatingPassword" placeholder="Password" v-model="_password" required="required">
                <label for="floatingPassword">Password</label>
            </div>

            <div class="checkbox mb-3">
                <label>
                    <input type="checkbox" value="remember-me" v-model="_remember"> Remember me
                </label>
            </div>
            <button :disabled="_is_loading" class="w-100 btn btn-lg btn-primary" v-on:click="login()">Sign in</button>
        </form>
    </main>
</template>

<script>
export default {
    data() {
      return {
        _is_loading: false,
        _password: "",
        _username: "",
        _remember: true,
        _error: false
      }
    },
    methods: {
      async getUser(token)
      {
        let url = `${process.env.VUE_APP_API}/user/profile`;
        let response = await fetch(url, {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
              "Access-Control-Allow-Origin" : "*"
          }
        })

        if(response.ok) 
        {
          return await response.json();

        }
        else
        {
          console.log("HTTP Error: " + response.status);
          return {}
        }
      },
      saveLoginData(token, user)
      {
        if (localStorage)
        {
          localStorage.setItem('user_token', token)
          localStorage.setItem('user_rights', user['rights'])
          localStorage.setItem('user_profile_username', user['profile']['username'])
          localStorage.setItem('user_profile_firstname', user['profile']['first_name'])
          localStorage.setItem('user_profile_lastname', user['profile']['last_name'])

          window.dispatchEvent(new CustomEvent('login-data-localstorage-event', {

          }));
        }
      },
      async login()
      {
        document.getElementById("authForm").classList.remove('was-validated');

        this._error = false

        if(!document.getElementById("authForm").checkValidity())
        {
            event.preventDefault()
            event.stopPropagation()
            document.getElementById("authForm").classList.add('was-validated')

            return 
        }

        this._is_loading = true;

        let url = `${process.env.VUE_APP_API}/auth/login`;
        let response = await fetch(url, {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              "Access-Control-Allow-Origin" : "*"
          },
          body: JSON.stringify({              
              'username': this._username,
              'password': this._password,
          })
        })

        if (response.ok) 
        {            
            let json = await response.json();

            console.log(json)

            var error = json['error']

            if(error)
            {
              this._error = true;
              this._is_loading = false;
              return 
            }

            // if(success)
            // {
              let token = json['token']

              let user = await this.getUser(token);

              console.log(user)
              
              if(user)
              {
                  this.saveLoginData(token, user)

                  this._is_loading = false;
            }
            else
            {
              this._is_loading = false;

              this._error = true;
            }
        } 
        else 
        {
            console.log("HTTP Error: " + response.status);
            this._is_loading = false;

            this._error = true;
        }
      }
    },
    computed: {

    },
    async mounted() {
      // console.log(process.env.VUE_APP_API)
    }
}
</script>

<style scoped>
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}
html,
body {
  height: 100%;
}

body {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-signin {
  max-width: 330px;
  padding: 15px;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>