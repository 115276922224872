import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/orders',
    name: 'OrdersMain',
    component: function () {
      return import('../views/OrdersView.vue')
    }
  },
  {
    path: '/payouts',
    name: 'PayoutsView',
    component: function () {
      if(enabledFull() || isMerchant()) {
        return import('../views/PayoutsView.vue')
      }
      
      return import('../views/HomeView.vue')
    }
  },
  {
    path: '/orders/:status/:page',
    name: 'OrdersMainFiltered',
    component: function () {
      if(enabledFull() || isMerchant()) {
        return import('../views/OrdersView.vue')
      }

      return import('../views/HomeView.vue')
    },
    props: { 
      default: true
    }
  },
  {
    path: '/agents',
    name: 'AgentsView',
    component: function () {
      if(enabledFull()) {
        return import('../views/AgentsView.vue')
      }

      return import('../views/HomeView.vue')
    }
  },
  {
    path: '/accounts',
    name: 'AccountsMain',
    component: function () {
      if(enabledFull()) {
        return import('../views/AccountsView.vue')
      }

      return import('../views/HomeView.vue')
    }
  },
  {
    path: '/entries',
    name: 'EntriesMain',
    component: function () {
      if(enabledFull()) {
        return import('../views/EntriesView.vue')
      }

      return import('../views/HomeView.vue')
    }
  },
  {
    path: '/analytics',
    name: 'AnalyticsMain',
    component: function () {
      if(enabledFull() || isMerchant()) {
        return import('../views/AnalyticsView.vue')
      }

      return import('../views/HomeView.vue')
    }
  },
  {
    path: '/stat',
    name: 'StatMain',
    component: function () {
      if(enabledFull()) {
        return import('../views/StatView.vue')
      }

      return import('../views/HomeView.vue')
    }
  },
  {
    path: '/users',
    name: 'UsersMain',
    component: function () {
      if(enabledFull()) {
        return import('../views/UsersView.vue')
      }

      return import('../views/HomeView.vue')
    }
  },
  {
    path: '/order/:order_id',
    name: 'OrderView',
    component: function () {
      if(enabledFull() || isMerchant()) {
        return import('../views/OrderView.vue')
      }

      return import('../views/HomeView.vue')
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

function enabledFull() {
  var rights = localStorage.getItem('user_rights');

  if(rights && rights.includes('admin'))
  {
      return true
  }

  if(rights && rights.includes('user'))
  {
      return true
  }
  return false
}

function isMerchant() {
  var rights = localStorage.getItem('user_rights');

  if(rights && rights.includes('merchant'))
  {
      return true
  }
  return false
}

function isAdmin() {
  var rights = localStorage.getItem('user_rights');

  if(rights && rights.includes('admin'))
  {
      return true
  }
  return false
}

export default router
