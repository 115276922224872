<script setup>
import LoggedLayout from './LoggedLayout.vue'
import LoginLayout from './LoginLayout.vue'
</script>

<template>
    <LoggedLayout v-if="_is_logged">
        <slot></slot>
    </LoggedLayout>
    <LoginLayout v-else>
        <slot></slot>
    </LoginLayout>
</template>

<script>
export default {

    data() {
      return {
        _is_logged: false,
        _debug: false
      }
    },
    methods: {
        checkToken()
        {
            // if(this._debug)
            // {
            //     this._is_logged = true;
            //     return
            // }

            let token = localStorage.getItem('user_token');
            let user_rights = localStorage.getItem('user_rights');
            if(token == undefined || token == null || token == 'undefined' || token == 'null') {
                console.log("*** checkToken 1")
                this._is_logged = false;
            } else {
                console.log("*** checkToken 2")
                this._is_logged = true;
            }
            // if(token && user_rights)
            // {
            //     if(user_rights.includes('admin'))
            //     {
            //         this._is_logged = true;
            //     }
            //     else
            //     {
            //         this._is_logged = false;
            //     }
            // }
            // else 
            // {
            //     this._is_logged = false;
            // }

            console.log("*** checkToken " + this._is_logged)
        }
    },
    computed: 
    {

    },
    async mounted()
    {
        window.addEventListener('login-data-localstorage-event', (event) => {
            console.log("*** login-data-localstorage-event")
            
            this.checkToken()
        });

        window.addEventListener('logout-data-localstorage-event', (event) => {
            console.log("*** logout-data-localstorage-event")
            
            this._is_logged = false;
        });

        this.checkToken()
    }
}
</script>