<template>
  <MainTemplate selected="dashboard">
    <div class="home text-center">
      <img alt="Vue logo" src="../assets/logo-full.png" width="390">
      <div class="hello">
        <h1>Welcome to your Shape Blade</h1>
      </div>
    </div>
  </MainTemplate>
</template>

<script>
// @ is an alias to /src
import MainTemplate from '@/components/MainTemplate.vue'

export default {
  name: 'HomeView',
  components: {
    MainTemplate
  }, 
  methods: {
    
  },
  async mounted() {
    location = "/orders"
  }
}
</script>
