import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/bootstrap.min.css'

import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_API;
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';



function logout() {
    localStorage.removeItem('user_token');
    localStorage.removeItem('user_rights')
    localStorage.removeItem('sbe_user_profile_username')
    localStorage.removeItem('sbe_user_profile_firstname')
    localStorage.removeItem('sbe_user_profile_lastname')
    window.dispatchEvent(new CustomEvent('logout-data-localstorage-event', {

    }));
}

function isTokenExpired(token) {

    // return 
    if(token == undefined || token == null || token == 'undefined' || token == 'null') return true

    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date()).getTime() / 1000)) >= expiry;
}

setInterval(async() => {     
    var token = localStorage.getItem('user_token');

    if(token != null) {
        var expired = isTokenExpired(token)
        if(expired) {
            logout()
        }
    } 
    else {
        logout()
    }
}, 5000)

createApp(App).use(store).use(router).mount('#app')